import $ from "jquery";
window.jQuery = $;
window.$ = $;
import 'jquery-ui/ui/widgets/sortable'; // Import sortable widget
import 'jquery-ui/themes/base/theme.css'; // Import jQuery UI theme

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()
import "controllers"
// import 'stylesheets/application';

// require("turbolinks").start()

require("bootstrap/dist/js/bootstrap.bundle.min.js");

require("jquery-ui-dist/jquery-ui")

require("theme/bootstrap-datepicker.min.js")

require("theme/jquery.maskedinput.js")

require("theme/jquery.toast.js")

//bootstrap validations
require('custom/form-validation')

//sticky header
require('custom/header-sticky')

//preloader
// require('custom/preloader')

//back to top
require('custom/topTop')


//Header sticky on reverse scroll
require('custom/header-sticky-reverse')

//On scroll animations
// require('custom/aos')
require("custom/jquery.maskedinput.js")

//Lightbox for image/video popups
require('custom/glightbox')

//Portfolio filter / masonry layouts
require('custom/isotope-layout')

//background image/video parallax
require('custom/jarallax')

//Smooth scroll
require('custom/smooth-scroll')

//Inject image[svg] to svg
require('custom/svgInjector')

//Typed Text
require('custom/typed')

//Element parallax
require('custom/rellax')
require('masterslider/jquery.easing.min.js')
require('masterslider/masterslider.min.js')
// require('custom/swiper-bundle.min.js')

$.ajaxSetup({
  'global': true,
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});

$(function() {
  window.jQuery(".datepicker_tday").datepicker({
    format: "mm/dd/yyyy"
  })
  // window.jQuery(".datetimepicker1").datetimepicker()
  // window.jQuery("#sortable").sortable()

})

$(document).ready(function () {
  confirm_rails();
  $(".phone_no_masked").mask("(999) 999-9999");
});

function confirm_rails() {
  $(document).on('mousedown', 'a[data-confirm]', function (e) {
    let $ = window.$b;
    e.preventDefault();
    let link = window.jQuery(this)
    let message = link.data('confirm');
    let modal = window.jQuery("#modal_confirm");
    modal.find('.modal-body').text(message)
    let approve = modal.find('.approve')
    let approve_txt = link.attr('data-approve-text');
    approve.attr('data-method', link.data('method'));
    approve.attr('href', link.attr('href'));
    approve_txt == '' ? 'OK' : approve.text(approve_txt)
    modal.modal('show')
  });
}
